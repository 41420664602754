<template>
  <div>
    <!-- 执行结果 -->
    <el-row :gutter="4" class="mt15" style="display: flex">
      <el-col :span="3">
        <el-date-picker :clearable="false"
          style="width: 100%"
          v-model="queryObj.startTime"
          type="date"
          :placeholder="$t('Delivery.enforcementresult.SelectAStartTime')"
          size="small"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions0"
          @change="changeDate"
        >
        </el-date-picker>
      </el-col>
      <el-col :span="0.5">
   <span style="line-height: 30px; color: #dcdfe6">~</span>
</el-col>
      <el-col :span="3">
        <el-date-picker :clearable="false"
          style="width: 100%"
          v-model="queryObj.endTime"
          type="date"
          :placeholder="$t('Delivery.enforcementresult.SelectAEndTime')"
          size="small"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions1"
          @change="changeDate"
        >
        </el-date-picker>
      </el-col>
      <el-col :span="3">
        <el-input
          :placeholder="$t('Delivery.enforcementresult.Placeholder')"
          size="small"
          v-model="queryObj.keywords"
          @keyup.enter.native="search"
        >
        </el-input>
      </el-col>
      <el-col :span="3">
        <el-button type="primary" size="small" @click="search">{{
          $t("public.Inquire")
        }}</el-button>
      </el-col>
    </el-row>
    <el-row :gutter="16" style="padding: 10px 0 0 10px">
      <el-col :span="4">
        <el-button
          style="
            border-radius: 3px 0px 0px 3px;
            margin: 0px 0px 0px 0px;
            margin-left: 1px;
          "
          @click="delArr(delarr)"
          :type="typeInfo"
          :disabled="!hasSeleted"
          size="small"
          >{{ $t("public.Delete") }}</el-button
        >
        <el-button type="primary" @click="deleteAll" class="delbtn" size="small">{{
          $t("Delivery.distributionManagement.deleteAll")
        }}</el-button>
      </el-col>
    </el-row>
    <el-row :gutter="16" class="mt16">
      <div style="position: relative">
        <el-table
          ref="tableData"
          :row-key="getRowKeys"
          :data="tableData"
          lazy
          size="mini"
          stripe
          tooltip-effect="dark"
          class="sort_table"
          style="width: 100%"
          :row-style="iRowStyle"
          :cell-style="iCellStyle"
          :header-row-style="iHeaderRowStyle"
          :header-cell-style="{
            background: '#f7f8fa',
            color: '#606266',
            padding: '0px',
          }"
          :default-sort="{ prop: 'createTime', order: 'descending' }"
          @selection-change="handleSelectionChange"
          @sort-change="sort"
        >
          <el-table-column
            type="selection"
            align="center"
            width="60"
            :reserve-selection="true"
          >
          </el-table-column>
          <el-table-column
            prop="deviceName"
            :label="$t('public.DeviceName')"
            width="180"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="reqType"
            :label="$t('Delivery.enforcementresult.ReqType')"
            width="180"
            :show-overflow-tooltip="true"
          >
            <template v-slot="{ row }">
              {{ $t(`Delivery.enforcementresult.reqType.${row.reqType}`) }}
              <!-- <template v-if="row.reqType == 'installconfig'">安装合规策略</template>
              <template v-if="row.reqType == 'installpolicy'">安装设备策略</template>
              <template v-if="row.reqType == 'message'">消息</template>
              <template v-if="row.reqType == 'removedevice'">强制注销</template>
              <template v-if="row.reqType == 'logOut'">强制注销</template>
              <template v-if="row.reqType == 'must_install_application'">安装应用</template>
              <template v-if="row.reqType == 'InstallApplication'">安装应用</template>
              <template v-if="row.reqType == 'extractLog'">提取日志</template>
              <template v-if="row.reqType == 'InstalledApplicationList'">软件信息</template>
              <template v-if="row.reqType == 'DeviceInformation'">硬件信息</template>
              <template v-if="row.reqType == 'DeviceLock'">设备锁屏</template>
              <template v-if="row.reqType == 'removeappdata'">清除数据</template>
              <template v-if="row.reqType == 'InstallProfile'">安装配置文件</template>
              <template v-if="row.reqType == 'ManagedApplicationList'">受控软件</template>
              <template v-if="row.reqType == 'restartDevice'">设备重启</template>
              <template v-if="row.reqType == 'shutDown'">设备关机</template>
              <template v-if="row.reqType == 'RemoveApplication'">卸载应用</template>
              <template v-if="row.reqType == 'ClearPasscode'">清除密码</template>
              <template v-if="row.reqType == 'EnableLostMode'">设备丢失</template>
              <template v-if="row.reqType == 'DisableLostMode'">解除丢失</template>
              <template v-if="row.reqType == 'uploadlocation'">获取定位</template>
              <template v-if="row.reqType == 'EraseDevice'">恢复出厂</template> -->
            </template>
          </el-table-column>
          <el-table-column
            prop="cmdResult"
            :label="$t('Delivery.enforcementresult.CmdResult')"
            width="180"
            :show-overflow-tooltip="true"
          >
            <template v-slot="{ row }">
              <template v-if="row.cmdResult == 0">
                {{ $t("Delivery.enforcementresult.Waiting") }}
              </template>
              <template v-if="row.cmdResult == 1">
                {{ $t("Delivery.enforcementresult.Success") }}
              </template>
            </template>
          </el-table-column>
          <el-table-column
            prop="createTime"
            :label="$t('Delivery.enforcementresult.DeliveryTime')"
            :show-overflow-tooltip="true"
          >
            <template v-slot="{ row }">
              {{ row.createTime | dateformats }}
            </template>
          </el-table-column>
        </el-table>
      </div>
      <pagination
        style="margin-left: 10px"
        :page="currentPage"
        :limit="pageSize"
        :total="total"
        :pageSizes="[10, 20, 30, 40]"
        v-on:handleChildGetList="handleParentGetList"
      ></pagination>
    </el-row>
    <!-- 保存成功的弹框 -->
    <dialog-info
      :dialogInfo="Successdialog"
      @handleClose="handleClose"
      @determine="determine"
      :titleInfo="seccess"
      :width="'240px'"
    />
  </div>
</template>

<script>
import Nav from "@/components/hearder";
import pagination from "@/components/pagination/page.vue";
import Eldialog from "@/components/elDialog/index.vue";
import dialogInfo from "@/components/promptMessage/index";
export default {
  components: { Nav, pagination, Eldialog, dialogInfo },

  data() {
    return {
      typeInfo: "info", // 删除按钮默认灰色
      seccess: "",
      sorts: "cmd.createTime desc",
      orderColume: "createTime",
      orderRule: "DESC",
      currentPage: 1,
      pageSize: 10,
      total: 0,
      isCheckShow: false,
      checked: false,
      Successdialog: false,
      pickerOptions1: {},
      pickerOptions0: {},
      queryObj: {
        startTime: "",
        endTime: "",
        keywords: "",
      },
      delarr: [],
      tableData: [],
    };
  },
  computed: {
    hasSeleted() {
      return this.delarr && this.delarr.length;
    },
  },

  mounted() {
    this.getDatalist();
  },

  methods: {
    getRowKeys(row) {
      return row.commonUuid;
    },
    //表格行高
    iRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iHeaderRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iCellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return "padding:3px 0px";
    },
    handleClose() {
      this.Successdialog = false; //提示消息  关闭
    },
    determine() {
      this.Successdialog = false; //提示消息  关闭
    },
    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.currentPage = page;
      this.pageSize = limit;
      // 调用查询方法
      this.search();
    },
    // 日期
    changeDate() {
      let start = new Date(this.queryObj.startTime).getTime();
      let end = new Date(this.queryObj.endTime).getTime();
      this.pickerOptions0 = {
        disabledDate: (time) => {
          if (end != "") {
            return time.getTime() > Date.now() || time.getTime() > end;
          }
          return time.getTime() > Date.now();
        },
      };
      this.pickerOptions1 = {
        disabledDate: (time) => {
          return (
            time.getTime() < start - 24 * 60 * 60 * 1000 || time.getTime() > Date.now()
          );
        },
      };
    },
    // 列表
    async getDatalist() {
      //一个月前日期
      var getAnyMonth = function (symbol, n) {
        var symbol = symbol || "-";
        var nowDate = new Date();
        nowDate.setMonth(nowDate.getMonth() + n);
        var year = nowDate.getFullYear();
        var month = nowDate.getMonth() + 1;
        var day = nowDate.getDate();
        day = day < 10 ? "0" + day : day;
        month = month < 10 ? "0" + month : month;
        return year + symbol + month + symbol + day;
      }; //当前日期
      var getDate = function () {
        var date = new Date();
        var year = date.getFullYear();
        var mon = date.getMonth() + 1;
        var day = date.getDate();
        return (
          year + "-" + (mon < 10 ? "0" + mon : mon) + "-" + (day < 10 ? "0" + day : day)
        );
      };
      this.queryObj.startTime = getAnyMonth("-", -1);
      this.queryObj.endTime = getDate();
      this.changeDate();
      var parma = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        orderColume: this.orderColume,
        orderRule: this.orderRule,
        deviceName: this.queryObj.keywords,
        start: this.queryObj.startTime,
        end: this.queryObj.endTime,
      };
      const res = await this.$axios.post(
        "/httpServe/delivery/cmd/getDataInfo",
        parma,
        true
      );
      this.tableData = res.data.content;
      this.total = res.data.total;
    },
    // 查询
    async search() {
      var parma = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        orderColume: this.orderColume,
        orderRule: this.orderRule,
        deviceName: this.queryObj.keywords,
        start: this.queryObj.startTime,
        end: this.queryObj.endTime,
      };
      const res = await this.$axios.post(
        "/httpServe/delivery/cmd/getDataInfo",
        parma,
        true
      );
      this.tableData = res.data.content;
      this.total = res.data.total;
    },
    // 全部删除
    deleteAll() {
      var param = { ids: [] };
      this.$confirm(
        this.$t("Delivery.enforcementresult.CheckDelAll"),
        this.$t("public.PromptMessage"),
        {
          confirmButtonText: this.$t("public.Verify"),
          cancelButtonText: this.$t("public.Cancel"),
          customClass: "persdsd",
          type: "warning",
        }
      )
        .then(async () => {
          const res = await this.$axios.post(
            "/httpServe/delivery/cmd/delete",
            param,
            true
          );
          this.$message({
            message: this.$t("public.SuccessfullyDeleted"),
            type: "success",
            offset: 100,
          });
          this.currentPage = 1;
          this.getDatalist();
        })
        .catch(() => {
          return false;
        });
    },
    // 选中数组
    handleSelectionChange(val) {
      this.delarr = val;
      this.typeInfo = "primary";
      if (this.delarr.length == 0) {
        this.typeInfo = "info";
      }
    },
    // 批量删除
    delArr(val) {
      var ids = [];
      val.forEach((item) => {
        ids.push(item.commonUuid);
      });
      var param = { ids: ids };
      this.$confirm(
        this.$t("Delivery.enforcementresult.CheckDel"),
        this.$t("public.PromptMessage"),
        {
          confirmButtonText: this.$t("public.Verify"),
          cancelButtonText: this.$t("public.Cancel"),
          customClass: "persdsd",
          type: "warning",
        }
      )
        .then(async () => {
          const res = await this.$axios.post(
            "/httpServe/delivery/cmd/delete",
            param,
            true
          );
          this.$message({
            message: this.$t("public.SuccessfullyDeleted"),
            type: "success",
            offset: 100,
          });
          this.$refs.tableData.clearSelection();
          this.currentPage = 1;
          this.getDatalist();
        })
        .catch(() => {
          return false;
        });
    },
    //全选遮罩层
    maskLayer() {
      this.isCheckShow = true;
    },
    // 鼠标移出弹框
    leave() {
      this.isCheckShow = false;
    },
    //当前页全选
    curSelection() {
      this.$refs.tableData.toggleAllSelection();
    },
    //取消全选
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          row.forEach((item) => {
            item.checked = true;
          });
          this.$refs.tableData.toggleRowSelection(row);
        });
      } else {
        this.$refs.tableData.clearSelection();
      }
      this.isCheckShow = true;
    },
    // 排序
    sort(column) {
      if (column.prop == "createTime") {
        if (column.order == "ascending") {
          this.orderRule = "ASC";
        } else {
          this.orderRule = "DESC";
        }
        this.orderColume = column.prop;
      } else if (column.prop == "deviceName") {
        if (column.order == "ascending") {
          this.orderRule = "ASC";
        } else {
          this.orderRule = "DESC";
        }
        this.orderColume = column.prop;
      } else if (column.prop == "reqType") {
        if (column.order == "ascending") {
          this.orderRule = "ASC";
        } else {
          this.orderRule = "DESC";
        }
        this.orderColume = column.prop;
      } else if (column.prop == "result") {
        if (column.order == "ascending") {
          this.orderRule = "ASC";
        } else {
          this.orderRule = "DESC";
        }
        this.orderColume = column.prop;
      }
      this.getDatalist();
    },
  },
};
</script>

<style lang="scss" scoped>
.mt15 {
  padding: 10px 0 0 10px;
}

.mt16 {
  padding: 10px 18px 0 18px;
}

//全选弹框
.tableCheckBox {
  border: 1px solid #1e89e5;
  border-radius: 3px;
  width: 80px;
  background-color: #fff;
  position: absolute;
  top: 30px;
  left: 1%;
  z-index: 999;

  li {
    list-style: none;
    margin-top: -1px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #575656;
    cursor: pointer;
    font-weight: 400;
    font-family: Microsoft YaHei;
    font-size: 12px;

    div {
      display: inline-block;
    }
  }

  li:hover {
    color: #d38a08;
    text-decoration: underline;
  }
}

.el-button + .el-button {
  margin-left: 1px;
}
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
</style>
